<template>
  <div class="hero-slide">
    <nuxt-img
      class="hero-slide__image"
      :src="
        $device.isDesktop ? blockData.image.desktop : blockData.image.mobile
      "
      :alt="blockData.image.alt"
    />
    <div class="hero-slide__box" v-if="blockData.title">
      <div class="hero-slide__box__content">
        <h3
          v-if="blockData.introTitle"
          class="hero-slide__box__content__intro-title"
        >
          {{ blockData.introTitle }}
        </h3>
        <h2 v-if="blockData.title" class="hero-slide__box__content__title">
          {{ blockData.title }}
        </h2>
        <CustomButton
          v-if="blockData.link && blockData.link.label"
          class="hero-slide__box__content__button"
          specific-width="252px"
          theme="orange"
          size="normal"
          :link="localePath(blockData.link.url)"
        >
          {{ blockData.link.label }}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { CustomButton } from '~/components/General/FormElements';

export default defineComponent({
  name: 'HeroSlide',
  components: {
    CustomButton,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.hero-slide {
  z-index: 0;
  position: relative;
  aspect-ratio: 1920/870;
  &__image {
    @include for-desktop {
      position: var(--absolute);
      top: 0;
      left: 0;
    }
    height: var(--h-full);
    width: var(--w-full);
    object-fit: cover;
  }
  &__box {
    position: var(--relative);
    height: 100%;
    width: 100%;
    @include desktop-boxed-inner;
    z-index: 1;
    padding: 1.25rem 0 1.25rem 1.25rem;
    display: flex;
    align-items: center;
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: var(--relative);
      width: 50%;
      height: 100%;
      z-index: 1;
      padding: 1.25rem 0 1.25rem 1.25rem;
      &__intro-title {
        @include h5;
        width: min-content;
        padding: 0 2.5rem 0.625rem 0;
        margin: 0 0 0.625rem;
        color: var(--c-primary-light);
        border-bottom: 1px solid var(--c-primary-lightest);
      }
      &__title {
        @include h1;
      }
      &__button {
        margin-top: 1.875rem;
        @include background-button;
      }
    }
  }
  @include for-mobile {
    aspect-ratio: auto;
    &__box {
      padding: 1.25rem 0 1.25rem 0;
      &__content {
        padding: 1.25rem 0 1.25rem 0;
        width: 100%;
        &__title {
          @include h2;
        }
      }
    }
  }
}
</style>
