<template>
  <div class="hero-slider" v-if="sanitizedBlockData.heroSlides.length > 0">
    <SfCarousel
      v-if="sanitizedBlockData.heroSlides.length > 1"
      ref="homeCarousel"
      data-cy="hero-slider-carousel"
      :settings="{
        perView: 1,
        autoplay: 5000,
        breakpoints: {
          1023: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      }"
    >
      <SfCarouselItem
        v-for="(heroSlide, i) in sanitizedBlockData.heroSlides"
        :key="i"
      >
        <nuxt-link
          :to="localePath(heroSlide.link.url)"
          v-if="!heroSlide.link.label"
        >
          <HeroSlide :block-data="heroSlide" />
        </nuxt-link>
        <HeroSlide v-else :block-data="heroSlide" />
      </SfCarouselItem>
      <template #next="{ go }">
        <div class="next" @click="go('next')">
          <nuxt-img src="/header/right-arrow.svg" />
        </div>
      </template>
      <template #prev="{ go }">
        <div class="prev" @click="go('prev')">
          <nuxt-img src="/header/right-arrow.svg" />
        </div>
      </template>
    </SfCarousel>
    <div
      v-if="
        sanitizedBlockData.heroSlides &&
        sanitizedBlockData.heroSlides.length > 1
      "
      class="glide__bullets"
      data-glide-el="controls[nav]"
    >
      <button
        v-for="(slide, index) in sanitizedBlockData.heroSlides"
        :key="`${slide.title}-${index}`"
        class="glide__bullet"
        :data-glide-dir="`=${index}`"
      ></button>
    </div>
    <template v-if="sanitizedBlockData.heroSlides.length === 1">
      <nuxt-link
        :to="localePath(sanitizedBlockData.heroSlides[0].link.url)"
        v-if="!sanitizedBlockData.heroSlides[0].link.label"
      >
        <HeroSlide :block-data="sanitizedBlockData.heroSlides[0]" />
      </nuxt-link>
      <HeroSlide v-else :block-data="sanitizedBlockData.heroSlides[0]" />
    </template>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import HeroSlide from './HeroSlide.vue';

export default defineComponent({
  name: 'HeroSlider',
  components: {
    SfCarousel,
    HeroSlide,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { refs }) {
    const homeCarouselIndex = computed(
      () => refs?.homeCarousel?.glide?.index ?? 0
    );
    const { blockData } = props;
    const heroSlides = ref([]);
    blockData.slides
      .filter((slide) => slide.active)
      .forEach((slide) => {
        heroSlides.value.push({
          title: slide.title,
          introTitle: slide.intro_title,
          image: slide.image,
          bgColor: slide.text_color,
          link: slide.link,
        });
      });
    const sanitizedBlockData = computed(() => ({
      heroSlides: heroSlides.value || [],
    }));

    return {
      sanitizedBlockData,
      homeCarouselIndex,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider {
  min-height: 28.125rem;
  margin-bottom: 5rem;
  position: relative;
  @include for-mobile {
    min-height: 18.75rem;
    margin-bottom: 2rem;
  }
}
</style>

<style lang="scss">
.hero-slider {
  --carousel-width: 100%;
  .sf-carousel {
    &:hover {
      .sf-carousel__controls {
        visibility: visible;
        transition: visibility 0.5s 0.5s ease;
      }
    }
    &__controls {
      visibility: hidden;
      z-index: 1;
      .prev,
      .next {
        width: 2.5rem;
        height: 2.5rem;
        position: relative;
        @include pointer;
        > img {
          position: absolute;
          inset: 0;
          margin: auto;
          vertical-align: -2px;
        }
      }
      .prev > img {
        rotate: 180deg;
      }
    }
  }
}
</style>
