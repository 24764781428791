<template>
  <div :class="`horizontal-card__details__container-${details.layout}`">
    <div class="horizontal-card__details">
      <div
        class="horizontal-card__details__title"
        :class="
          details.subtitle ? 'horizontal-card__details__title-has-subtitle' : ''
        "
        v-html="details.title"
      />
      <div
        class="horizontal-card__details__subtitle"
        v-if="details.subtitle"
        v-html="details.subtitle"
      />
      <div
        class="horizontal-card__details__description"
        v-if="details.description"
        v-html="details.description"
      />
      <CustomButton
        v-if="details.ctaLabel && details.ctaLink"
        class="horizontal-card__details__cta"
        :link="
          !details.ctaLink.includes('http')
            ? localePath(`${details.ctaLink}`)
            : details.ctaLink
        "
        :target="details.ctaLink.includes('http') ? '_blank' : null"
        size="normal"
      >
        {{ details.ctaLabel }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomButton } from '~/components/General/FormElements';

export default defineComponent({
  name: 'HorizontalCardDetails',
  components: { CustomButton },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.horizontal-card__details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  height: 100%;
  &__title {
    @include h4;
  }
  &__description {
    @include text-16-regular-light_black;
    line-height: var(--line-height-26);
  }
}
@include to-tablet-max {
  .horizontal-card__details__container {
    &-boxed {
      .horizontal-card__details {
        padding: 0;
      }
    }
  }
}
@include from-desktop-min {
  .horizontal-card__details {
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
    &__title {
      @include h3;
      color: var(--c-primary);
      ::v-deep p {
        line-height: var(--line-spacing-35);
      }
      &-has-subtitle {
        @include h2;
        ::v-deep p {
          line-height: var(--line-spacing-45);
        }
      }
    }
    &__subtitle {
      @include h3;
      color: var(--c-primary);
    }
    &__description {
      @include text-16-regular-light_black;
      height: fit-content;
    }
  }
  .horizontal-card__details__container {
    &-boxed {
      display: flex;
      align-items: center;
      .horizontal-card__details {
        gap: 0;
        height: auto;
        margin: 0 5%;
        &__title {
          margin-bottom: 0.9375rem;
        }
        &__subtitle {
          margin-bottom: 1.875rem;
        }
        &__description {
          margin-bottom: 2.5rem;
        }
        &__cta {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
