var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sanitizedBlockData.heroSlides.length > 0)?_c('div',{staticClass:"hero-slider"},[(_vm.sanitizedBlockData.heroSlides.length > 1)?_c('SfCarousel',{ref:"homeCarousel",attrs:{"data-cy":"hero-slider-carousel","settings":{
      perView: 1,
      autoplay: 5000,
      breakpoints: {
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    }},scopedSlots:_vm._u([{key:"next",fn:function(ref){
    var go = ref.go;
return [_c('div',{staticClass:"next",on:{"click":function($event){return go('next')}}},[_c('nuxt-img',{attrs:{"src":"/header/right-arrow.svg"}})],1)]}},{key:"prev",fn:function(ref){
    var go = ref.go;
return [_c('div',{staticClass:"prev",on:{"click":function($event){return go('prev')}}},[_c('nuxt-img',{attrs:{"src":"/header/right-arrow.svg"}})],1)]}}],null,false,2051953727)},_vm._l((_vm.sanitizedBlockData.heroSlides),function(heroSlide,i){return _c('SfCarouselItem',{key:i},[(!heroSlide.link.label)?_c('nuxt-link',{attrs:{"to":_vm.localePath(heroSlide.link.url)}},[_c('HeroSlide',{attrs:{"block-data":heroSlide}})],1):_c('HeroSlide',{attrs:{"block-data":heroSlide}})],1)}),1):_vm._e(),_vm._v(" "),(
      _vm.sanitizedBlockData.heroSlides &&
      _vm.sanitizedBlockData.heroSlides.length > 1
    )?_c('div',{staticClass:"glide__bullets",attrs:{"data-glide-el":"controls[nav]"}},_vm._l((_vm.sanitizedBlockData.heroSlides),function(slide,index){return _c('button',{key:((slide.title) + "-" + index),staticClass:"glide__bullet",attrs:{"data-glide-dir":("=" + index)}})}),0):_vm._e(),_vm._v(" "),(_vm.sanitizedBlockData.heroSlides.length === 1)?[(!_vm.sanitizedBlockData.heroSlides[0].link.label)?_c('nuxt-link',{attrs:{"to":_vm.localePath(_vm.sanitizedBlockData.heroSlides[0].link.url)}},[_c('HeroSlide',{attrs:{"block-data":_vm.sanitizedBlockData.heroSlides[0]}})],1):_c('HeroSlide',{attrs:{"block-data":_vm.sanitizedBlockData.heroSlides[0]}})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }